.cotizador .form-control {
  background-color: #f6f3f2;
  border-radius: 0;
  /* background-color: #e2ddd4; */
}

.cotizador .cont-version .form-control {
  background-color: #211915;
  color: #ffffff;
  border: 2px solid #000000;
}

.cotizador .btn-version {
  background-color: #211915;
  color: #ffffff;
  border: 2px solid #c23625;
  line-height: 1;
}

.cotizador .btn-version:hover {
  background-color: #211915;
  border: 2px solid #009ecd;
}

.cotizador .color-option ul {
  display: inline-flex;
  /* margin-top: 12px; */
  padding-left: 0;
}

.cotizador .color-option ul li {
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
  width: 35px;
  height: 35px;
  transition: transform 0.2s;
  border-radius: 6px;
  box-shadow: 2px 3px 2px #333333;
  background-image: url("../../../images/assets/degradado.png");
  z-index: 1;
}

.cotizador .color-option ul .color-active {
  box-shadow: 2px 3px 2px #009ecd;
}

/* #paleta-color {
  display: none;
} */

.cotizador .color-option ul li:hover {
  transform: scale(1.1);
}

.linea-cotizador {
  border: 1px solid #76a9da;
  margin-bottom: 35px;
}

.cotizar-vinieta {
  color: #009ecd;
  font-size: 18px;
}

.entrada-minima {
  font-size: 10px;
  margin-bottom: 0;
}

.cotizador .titulo-legal {
  font-size: 18px;
  color: #c5470c;
  font-family: HyundaiSansHead-Medium;
}

.cotizador .texto-legal {
  font-size: 13px;
  text-align: justify;
  line-height: 1.1;
}

.cotizador .btn-cuota {
  font-size: 18px;
  width: 40px;
  height: 38px;
  /* background-color: transparent; */
  color: #000000;
  /* border: 1px solid #383838; */
  border-radius: 5px;
}

.cotizador .btn-cuota:hover {
  color: #ffffff;
  background-color: #009ecd;
}

.cotizador .btn-cuota-activa {
  font-size: 18px;
  width: 40px;
  height: 38px;
  background-color: #009ecd;
  color: #ffffff;
}

.cotizador .numero-cuota ul {
  display: inline-flex;
  padding-left: 0;
}

.cotizador .numero-cuota ul li {
  display: inline-block;
  margin-right: 8px;
  /* margin-bottom: 6px; */
  display: block;
  /* width: 35px;
  height: 35px; */
  transition: transform 0.2s;
  /* border-radius: 6px; */
}

.cotizador .precio-cont {
  border: 2px solid #76a9da;
  border-radius: 12px;
  line-height: 1;
  padding: 12px 24px;
}

.cotizador .dollar {
  font-size: 30px;
  font-family: HyundaiSansHead-Bold;
}

.cotizador .precio {
  font-size: 50px;
  font-family: HyundaiSansHead-Bold;
  margin-bottom: 0;
  line-height: 1;
}

.cotizador .iva {
  font-size: 12px;
  text-align: right;
  margin-bottom: 0;
  line-height: 1;
}

.cotizador .btn-cotizar {
  background-color: #003669;
  width: 140px;
  font-size: 21px;
  font-family: HyundaiSansHead-Medium;
  border: 0;
  padding: 12px 0;
  border-radius: 8px;
}

.cotizador .btn-cotizar:hover {
  background-color: #00294e;
}

.cotizador .condiciones label {
  font-size: 12px;
  padding-top: 4px;
}

.cotizador .plane-icon {
  height: 22px;
}

@media (max-width: 576px) {
  .linea-cotizador {
    margin-bottom: 18px;
  }

  .cotizador .color-option ul li {
    height: 25px;
    width: 25px;
  }

  .cotizador .numero-cuota ul {
    margin-bottom: 0;
  }

  .cotizador .color-option ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  .cotizador .titulo-legal {
    font-size: 16px;
  }

  .cotizador .texto-legal {
    font-size: 12px;
    margin-bottom: 0;
  }

  .cotizador .precio {
    font-size: 35px;
  }
  .cotizador .btn-cotizar {
    background-color: #003669;
    width: 130px;
    font-size: 18px;
  }
  .cotizador .plane-icon {
    height: 20px;
  }
}
